
import { GrowthBook } from '@growthbook/growthbook'
import { Component, Vue } from 'vue-property-decorator'
import SnackBar from '@/components/SnackBar/Index.vue'
import { FeatureFlags } from './shared/featureFlags';

@Component({
  components: {
    SnackBar,
  },
})
export default class App extends Vue {
  private growthbook = new GrowthBook({
      apiHost: process.env.VUE_APP_GROWTHBOOK_HOST,
      clientKey: process.env.VUE_APP_GROWTHBOOK_API_KEY,
      enableDevMode: true,
      trackingCallback: (experiment, result) => {
        // TODO: Use your real analytics tracking system
        console.log('Viewed Experiment', {
          experimentId: experiment.key,
          variationId: result.key,
        })
      },
    })

  mounted() {
    this.growthbook.loadFeatures({ timeout: 1000 })
    .then(() => {
      console.log('GrowthBook Features Loaded')
      this.checkPosthogFeatureFlag()
    }).catch((err) => {
      console.error('Error loading GrowthBook Features', err)
    })
  }

  checkPosthogFeatureFlag() {
    const featureFlag = this.growthbook?.isOn('enable-posthog')
    const enableIncidentsModule = this.growthbook.getFeatureValue(FeatureFlags.enableIncidentsModule, {
      enabledCompanyIds: [],
      all: false,
    })
    const termsAndConditions = this.growthbook.getFeatureValue('accept-terms-and-conditions', {
      enabledCompanyIds: [],
      all: false,
    })
    if(termsAndConditions) {
      localStorage.setItem('termsAndConditions', JSON.stringify(termsAndConditions));
    }
    if (featureFlag) {
      this.injectPosthogScript()
    }
    if (enableIncidentsModule) { 
      const companyId = this.$store.state.AuthModule.AuthState.company_id;
      if (enableIncidentsModule.all) { 
        localStorage.setItem("enabledMenuIncidents", "true");
        return;
      }
      if (enableIncidentsModule.enabledCompanyIds.includes(companyId as unknown as never)) { 
        localStorage.setItem("enabledMenuIncidents", "true");
        return;
      }
      localStorage.setItem("enabledMenuIncidents", "false");
    }
  }

  injectPosthogScript() {
    const script = document.createElement('script')
    script.textContent = `
    !function(t,e){var o,n,p,r;e.__SV||(window.posthog=e,e._i=[],e.init=function(i,s,a){function g(t,e){var o=e.split(".");2==o.length&&(t=t[o[0]],e=o[1]),t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}}(p=t.createElement("script")).type="text/javascript",p.async=!0,p.src=s.api_host+"/static/array.js",(r=t.getElementsByTagName("script")[0]).parentNode.insertBefore(p,r);var u=e;for(void 0!==a?u=e[a]=[]:a="posthog",u.people=u.people||[],u.toString=function(t){var e="posthog";return"posthog"!==a&&(e+="."+a),t||(e+=" (stub)"),e},u.people.toString=function(){return u.toString(1)+".people (stub)"},o="capture identify alias people.set people.set_once set_config register register_once unregister opt_out_capturing has_opted_out_capturing opt_in_capturing reset isFeatureEnabled onFeatureFlags getFeatureFlag getFeatureFlagPayload reloadFeatureFlags group updateEarlyAccessFeatureEnrollment getEarlyAccessFeatures getActiveMatchingSurveys getSurveys onSessionId".split(" "),n=0;n<o.length;n++)g(u,o[n]);e._i.push([i,s,a])},e.__SV=1)}(document,window.posthog||[]);
    posthog.init('phc_1FSCpTzVtCcX7A9eww1ksLViTt7Ee46JgBqG8NHh2v4',{api_host:'https://posthog-mxrkb-u10077.vm.elestio.app'})
    `
    document.head.appendChild(script)
  }
}
