import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store'
import Vuesax from 'vuesax'
import VueMaterial from 'vue-material'
import VueHead from 'vue-head'
import VueMixpanelBrowser from 'vue-mixpanel-browser'
import { MixpanelPluginOptions, } from 'vue-mixpanel-browser'
import VueIntercom from '@mathieustan/vue-intercom'
import VueDebounce from 'vue-debounce'
import VueEllipseProgress from 'vue-ellipse-progress'
import VueDatePicker from '@mathieustan/vue-datepicker'

import 'vuesax/dist/vuesax.css'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'
import 'material-icons/iconfont/material-icons.css'
import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css'
import '@/assets/style.css'
import { GrowthBookVuePlugin } from './utils/growthbook/growthbook'
const { growthbookEnableDevMode, growthbookUrlApi } = useEnvironment();

import filters from '@/filters'

import useEnvironment from './utils/useEnvironments'

filters.forEach((f) => {
  Vue.filter(f.name, f.execute)
})

const options: MixpanelPluginOptions = {
  token: '17308baf4a16c6e24c4eb05ad23f3c98',
  config: { debug: true, }, // Mixpanel config
}

Vue.config.productionTip = false

Vue.use(GrowthBookVuePlugin, {
  featuresEndpoint: growthbookUrlApi,
  enableDevMode: growthbookEnableDevMode,
})
Vue.use(VueMaterial)
Vue.use(VueHead)
Vue.use(VueDebounce)
Vue.use(VueEllipseProgress)
Vue.use(Vuesax, {
  colors: {
    primary: '#056ee0',
    success: '#77dd77',
    warning: '#f6d949',
    danger: '#fc5974',
    info: '#2cd5db',
    white: '#ffffff',
  },
})
Vue.use(VueMixpanelBrowser, options)
Vue.use(VueDatePicker, {
  lang: 'es',
})

// ---------- appId de production -------------
Vue.use(VueIntercom, { appId: 'g1e4n990', })

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
